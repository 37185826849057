
import Swiper from 'swiper'
import { Navigation, Controller, Grid, Pagination } from 'swiper/modules'

function QuickOneSwipe(sel, maxTotal=1) {
    this.sel = sel
    this.quickOneSwiper = null
    this.self = this
    this.doRandomInitialSlide = false
    this.maxTotal = maxTotal
}

QuickOneSwipe.prototype.init = function() {
    let initialSlide = 0
    let t = this
    if (this.doRandomInitialSlide) {
        let totalCount = $(this.sel + " .swiper-slide").length
    }
    let breakpoints = {}
    this.quickOneSwiper = new Swiper(this.sel + " .quick-one-swipe-swiper", {
        modules: [Navigation, Controller, Grid, Pagination],
        init: false,
        loop: true,
        spaceBetween: 20,
        slidesPerView: 1,
        slidesPerGroup: 1,
        slideToClickedSlide: true,
        //watchSlidesVisibility: true,
        watchSlidesProgress: true,
        navigation: {
            nextEl: this.sel + " .swiper-button-next",
            prevEl: this.sel + " .swiper-button-prev",
        },
        pagination: {
            enabled:true,
            clickable: true,
            el: "#quick-one-dots .dots",
        },
        breakpoints: breakpoints
    })
    this.quickOneSwiper.on('transitionEnd', function() {
        $('#quick-one-captions-inner').html($(t.sel +  ' .swiper-slide-active .caption').html())
    });
    this.quickOneSwiper.on('init', function() {
        $('#quick-one-captions-inner').html($(t.sel +  ' .swiper-slide-active .caption').html())
    });
    if (this.quickOneSwiper) {
        this.quickOneSwiper.init()
    }
}

//////////////////////////////////////////////////////////////////////////////////////////
QuickOneSwipe.prototype.resize = function (evt) {
    
}

export default QuickOneSwipe