import Swiper from 'swiper'
import { Navigation, Controller, Grid, Pagination } from 'swiper/modules'

function QuickThumbs(sel, doRandomInitialSlide=true, maxTotal=4) {
    this.sel = sel
    this.quickThumbsSwiper = null
    this.self = this
    this.doRandomInitialSlide = doRandomInitialSlide
    this.maxTotal = Number(maxTotal)
}

QuickThumbs.prototype.init = function() {
    let initialSlide = 0
    if (this.doRandomInitialSlide) {
        let totalCount = $(this.sel + " .swiper-slide").length
        initialSlide = Math.floor(Math.random() * totalCount)

        // rearrange html - initialSlide in swiper is only good if it loops
        // the 2x2 grid is unloopable
        // thus, we need to actually rearrange the JS to start at a ranom spot at index 0
        // otherwise the pagination feels funny
        let thtml = ''
        let i = 0
        $(this.sel + " .swiper-slide").each(function () {
            if (i >= initialSlide) {
                thtml += $(this).prop('outerHTML')
            }
            i++
        })
        i = 0
        $(this.sel + " .swiper-slide").each(function () {
            if (i<initialSlide) {
                thtml += $(this).prop('outerHTML')
            }
            i++
        })
        $(this.sel + ' .swiper-wrapper').html(thtml)
        initialSlide = 0
    }
    let maxTotalMid = (this.maxTotal>1) ? 2 : 1
    let isSide = $(this.sel).hasClass('side');

    let maxTotalOverride = this.maxTotal

    //4 and 3 breakpoints
    let breakpointsJson = {}
    if (this.maxTotal === 4) {
        maxTotalOverride = 2 // super ghetto bugs with swiper - we need to init at 2 for the 2x2 grid to work even though breakpoints later override this
        breakpointsJson = {
            // when window width is >= Xpx
            0: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                pagination: {
                    enabled:true,
                },
                loop: false,
                grid: {
                    rows: 2,
                    fill: 'row'
                },
            },
            900: {
                slidesPerView: 3,
                slidesPerGroup: 3,
                pagination: {
                    enabled:false,
                },
                loop: true,
                grid: {
                    rows: 1,
                },
            },
            1200: {
                slidesPerView: this.maxTotal,
                slidesPerGroup: this.maxTotal,
                pagination: {
                    enabled:false,
                },
                loop: true,
                grid: {
                    rows: 1
                },
            }
        }
    } else {
        breakpointsJson = {
            // when window width is >= Xpx
            0: {
                slidesPerView: 1,
                slidesPerGroup: 1,
                pagination: {
                    enabled:true,
                }
            },
            550: {//$breakpoint-quickthumbs
                slidesPerView: maxTotalMid,
                slidesPerGroup: maxTotalMid,
                pagination: {
                    enabled:false,
                }
            },
            900: {
                slidesPerView: this.maxTotal,
                slidesPerGroup: this.maxTotal,
                pagination: {
                    enabled:false,
                }
            }
        }
    }

    //is side - force one
    if (isSide) {
        breakpointsJson = {
            // when window width is >= Xpx
            0: {
                slidesPerView: 1,
                slidesPerGroup: 1,
                pagination: {
                    enabled:true,
                }
            }
        }
    }
    this.quickThumbsSwiper = new Swiper(this.sel + " .quick-thumbs-swiper", {
        modules: [Navigation, Pagination, Controller, Grid],
        init: false,
        loop: true,
        initialSlide: initialSlide,
        // loopedSlides: 10,
        spaceBetween: 20,
        slidesPerView: maxTotalOverride,
        slidesPerGroup: maxTotalOverride,
        slideToClickedSlide: false,
        //centeredSlides: true,
        //watchSlidesVisibility: true,
        watchSlidesProgress: true,
        navigation: {
            nextEl: this.sel + " .swiper-button-next",
            prevEl: this.sel + " .swiper-button-prev",
        },
        pagination: {
            enabled:true,
            clickable: true,
            el: this.sel + "-dots .dots",
        },
        breakpoints: breakpointsJson
    })
    if (this.quickThumbsSwiper) {
        this.quickThumbsSwiper.init()
    }
}

//////////////////////////////////////////////////////////////////////////////////////////
QuickThumbs.prototype.resize = function (evt) {
    
}

export default QuickThumbs