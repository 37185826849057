import QuickThumbs from 'site/components/QuickThumbs'
let quickThumbsPrep = {
    homeShows: null,
    homeTours: null,
    allThumbs: [],
    init: function () {
        quickThumbsPrep.setupSlides()
    }, //init
    setupSlides: function () {
        $('.quick-thumbs').each(function () {
            let id = $(this).attr('id')
            let dotsId = $(this).attr('data-dots-id')
            let quickThumbsMaxTotal = $(this).attr('data-max-total')
            let quickThumbsRandomInitial = ($(this).attr('data-random-initial'))
            let slideshow = new QuickThumbs('#'+id, quickThumbsRandomInitial, quickThumbsMaxTotal)
            slideshow.init()
            if ($(this).hasClass('shows')) {
                quickThumbsPrep.homeShows = slideshow
                $('.hook-home-shows-mobile-prev').on('click', quickThumbsPrep.homeShowsPrev)
                $('.hook-home-shows-mobile-next').on('click', quickThumbsPrep.homeShowsNext)
            } else if ( $(this).hasClass('tours') ) {
                quickThumbsPrep.homeTours = slideshow
                $('.hook-home-tours-mobile-prev').on('click', quickThumbsPrep.homeToursPrev)
                $('.hook-home-tours-mobile-next').on('click', quickThumbsPrep.homeToursNext)
            }

            // add to dotsId
            quickThumbsPrep.allThumbs[dotsId] = slideshow
        });

        $('.quick-thumbs-dots-outer .dots-prev').on('click', quickThumbsPrep.quickDotsPrev)
        $('.quick-thumbs-dots-outer .dots-next').on('click', quickThumbsPrep.quickDotsNext)


    },
    homeShowsPrev: function() {
        quickThumbsPrep.homeShows.quickThumbsSwiper.slidePrev()
    },
    homeShowsNext: function() {
        quickThumbsPrep.homeShows.quickThumbsSwiper.slideNext()
    },
    homeToursPrev: function() {
        quickThumbsPrep.homeTours.quickThumbsSwiper.slidePrev()
    },
    homeToursNext: function() {
        quickThumbsPrep.homeTours.quickThumbsSwiper.slideNext()
    },
    quickDotsPrev: function() {
        const dotsId = $(this).attr('data-dots-id')
        quickThumbsPrep.allThumbs[dotsId].quickThumbsSwiper.slidePrev()
    },
    quickDotsNext: function() {
        const dotsId = $(this).attr('data-dots-id')
        quickThumbsPrep.allThumbs[dotsId].quickThumbsSwiper.slideNext()
    },
}
export default quickThumbsPrep