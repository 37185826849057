//DEFAULTS
import "imports/core-js/stable";
import "imports/regenerator-runtime/runtime";

//import overlay from 'site/overlay'
import mediaOverlay from 'site/mediaOverlay'
import site from 'site/site'
import global from 'site/global'
import gallery from 'site/gallery'
import quickThumbsPrep from 'site/quickThumbsPrep'
import quickOneSwipePrep from 'site/quickOneSwipePrep'
import home from 'site/home'
import siteScroll from 'site/scroll'
import mobileNav from 'site/mobileNav'
import siteAOS from 'site/site.aos'
import dropdowns from 'site/dropdowns'
import combobox from 'site/combobox'
import expandables from 'site/expandables'
import filters from 'site/filters'

$(document).ready(function() {
    mobileNav.init()
    site.init()
    filters.init()
    expandables.init()
    global.init()
    mediaOverlay.init()
    siteScroll.init()
    gallery.init()
    home.init()
    dropdowns.init()
    combobox.init()
    quickThumbsPrep.init()
    quickOneSwipePrep.init()
    setTimeout( function() {
        siteAOS.init()
        siteAOS.refreshAOS()
    },500)
})
