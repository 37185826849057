import gsap, {Power4} from 'imports/gsap'

import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'

let mediaOverlay = {
    swiper: null,
    mediaOverlayIsOpen: false,
    youtubeId: '',
    ytArray: {},
    yt: null,
    ytInterval: null,
    ytOverlayIsReady: false,
    init: function () {
        $('.mediaOverlay-hook').on('click', mediaOverlay.doMediaOverlayHandle);
        $('#mediaOverlay .overlay-x').on('click', mediaOverlay.doMediaOverlayClose);
        $(document).keyup(function (e) {
            if (mediaOverlay.mediaOverlayIsOpen) {
                if (e.keyCode === 27) { // escape key
                    mediaOverlay.doMediaOverlayClose();
                }
            }
        })

        mediaOverlay.ytArray = {}
        let thtml = $('#swiper-popup-meta').html()
        $('#swiper-super-outer').html(thtml)


        if (thtml === undefined || thtml === null || thtml.trim() === "") {
            // do nothing
        } else {
            mediaOverlay.mediaOverlayIsOpen = true
            mediaOverlay.swiper = new Swiper("#swiper-super-outer .swiper", {
                modules: [Navigation, Pagination],
                init: false,
                loop: true,
                initialSlide: 0,
                spaceBetween: 20,
                slidesPerView: 1,
                slidesPerGroup: 1,
                slideToClickedSlide: true,
                //watchSlidesVisibility: true,
                watchSlidesProgress: true,
                navigation: {
                    nextEl: "#swiper-super-outer .swiper-button-next",
                    prevEl: "#swiper-super-outer .swiper-button-prev",
                },
                pagination: {
                    enabled: true,
                    clickable: true,
                    el: "#media-dots .dots",
                },
            })
            mediaOverlay.swiper.on('slideChangeTransitionEnd', mediaOverlay.onSlideChange)
            mediaOverlay.swiper.on('afterInit', mediaOverlay.onSlideInit)
            mediaOverlay.swiper.init()
        }



    }, //init


    onSlideChange(e, a, b) {
        mediaOverlay.ytVideoStop()
        let ytHolder = $('#mediaOverlayInner .swiper-slide-' + e.realIndex);
        if (ytHolder) {
            let ytHolderId = ytHolder.attr('data-random-id')
            let ytHolderYoutubeId = ytHolder.attr('data-youtube-id')
            mediaOverlay.ytVideoPlay(ytHolderId, ytHolderYoutubeId)
        }
    },
    onSlideInit(e) {
        mediaOverlay.initYT()
    },
    initYT() {
        if (!youTubeIsReady) {
            mediaOverlay.ytInterval = setTimeout(mediaOverlay.initYT, 500)
        } else {
            mediaOverlay.initYT2()
        }
    },
    initYT2() {
        clearTimeout(mediaOverlay.ytInterval)

        $('#mediaOverlayInner .swiper-wrapper .swiper-slide').each(function () {
            let ytHolderId = 'youtube-' + $(this).attr('data-random-id')
            let ytHolderYoutubeId = $(this).attr('data-youtube-id')
            if (ytHolderId && ytHolderYoutubeId) {
                $('.ytholder', this).attr('id', ytHolderId)
            }
        })
    },

    doMediaOverlayHandle: function () {
        let initialSlide = $(this).attr('data-index')
        mediaOverlay.doMediaOverlayOpenReveal(initialSlide)
    },

    doMediaOverlayOpenReveal: function (initialSlide = 0) {
        mediaOverlay.swiper.slideTo(initialSlide)

        let ytHolder = $('#mediaOverlayInner .swiper-slide-' + initialSlide);
        if (ytHolder) {
            let ytHolderId = ytHolder.attr('data-random-id')
            let ytHolderYoutubeId = ytHolder.attr('data-youtube-id')
            mediaOverlay.ytVideoPlay(ytHolderId, ytHolderYoutubeId)
        }

        gsap.fromTo($('#mediaOverlay'), 1,
            {
                opacity: 0,
            },
            {
                opacity: 1,
                delay: 0,
                ease: Power4.easeInOut,
                onComplete: mediaOverlay.doMediaOverlayOpenReveal2,
            }
        )
        $('#mediaOverlay').css('visibility', 'visible')
    },
    doMediaOverlayOpenReveal2: function () {

    },

    doMediaOverlayClose: function () {
        gsap.fromTo($('#mediaOverlay'), 0.5,
            {
                opacity: 1,
            },
            {
                opacity: 0,
                delay: 0,
                ease: Power4.easeInOut,
                onComplete: mediaOverlay.doMediaOverlayClose2
            }
        );
        mediaOverlay.mediaOverlayIsOpen = false
    },
    doMediaOverlayClose2: function () {
        $('#mediaOverlay').css('visibility', 'hidden')

        mediaOverlay.ytVideoStop()
    },

    ytVideoPlay(ytHolderId, ytHolderYoutubeId) {
        ytHolderId = 'youtube-'+ytHolderId
        try {
            if (!mediaOverlay.ytArray[ytHolderId]) {
                mediaOverlay.ytArray[ytHolderId] = new YT.Player(ytHolderId, {
                    height: '390',
                    width: '640',
                    videoId: ytHolderYoutubeId,
                    host: 'https://www.youtube-nocookie.com',
                    playerVars: {
                        //origin: window.location.host,
                        'autoplay': 1,
                        'controls': 1,
                        'playsinline': 1,
                        'modestbranding': 1,
                        'rel': 0
                    },
                    events: {
                        //'onReady': mediaOverlay.onPlayerReady,
                        //'onStateChange': mediaOverlay.onPlayerStateChange
                    }
                });
            } else {
                mediaOverlay.ytArray[ytHolderId].playVideo();
            }
        } catch (e) {
            console.error('vid error', e)
        }
    },

    ytVideoStop() {
        Object.values(mediaOverlay.ytArray).forEach(player => {
            try {
                if (player && typeof player.stopVideo === 'function') {
                    player.stopVideo();
                }
            } catch (e) {
                // Handle any errors, possibly log them or ignore depending on your requirement
            }
        });
    },

    onPlayerReady(e) {
        mediaOverlay.ytOverlayIsReady = true
        $('.overlay-hook-yt-hider').css('visibility', 'visible')
    },
    onPlayerStateChange(e) {
        if (e.data === YT.PlayerState.ENDED) {
            //overlay.doOverlayClose()
        }
    },

}
export default mediaOverlay;
