//import $ from 'imports/jquery'
import { gsap, CSSPlugin, ScrollToPlugin } from 'imports/gsap/all'
import { Power4 } from 'imports/gsap'
gsap.registerPlugin(ScrollToPlugin);
let global = {
    geolocationHolderId: null,
    init: function () {
        $('.header .sign-up').on('click',global.gotoSignup)

        $('.jump').on('click',global.jump)

        $('.geo-icon').on('click',global.geoSearch)
    },
    ////////////////////////////////////////////////////////////////////////////////////
    gotoSignup: function () {
        gsap.to(window, {duration: 1, ease: Power4.easeInOut, scrollTo: {y: '#mc-email-classy', offsetY: 0}});
    },

    jump: function() {
        let tid = '#' + $(this).attr('data-jump')
        gsap.to(window, {duration: 0.75, ease: Power4.easeInOut, scrollTo: {y: tid, offsetY: 70}});
    },

    geoSearch: function() {
        const tid = $(this).attr('data-geo-icon-id')
        let val = '#' + tid
        global.geolocationHolderId = val
        global.getLocation()
    },

    getLocation: function() {
        if (navigator.geolocation) {
            global.geoShowWaitMessage()
            const options = {
                enableHighAccuracy: true,
                timeout: 15000,
                maximumAge: 0,
            };
            navigator.geolocation.getCurrentPosition(global.getLocationSuccess, global.getLocationError, options);
        } else {
            alert('Geolocation is not supported by this browser.')

        }
    },
    getLocationSuccess(position) {
        $(global.geolocationHolderId + '-lat').val(position.coords.latitude)
        $(global.geolocationHolderId + '-lon').val(position.coords.longitude)
        $(global.geolocationHolderId).submit()
    },

    getLocationError(err) {
        alert(err.message)
        global.geoHideWaitMessage()
    },

    geoShowWaitMessage() {
        $(global.geolocationHolderId + ' .fields.wait').show()
        $(global.geolocationHolderId + ' .fields.normal').hide()
        $(global.geolocationHolderId + '-button').hide()
    },
    geoHideWaitMessage() {
        $(global.geolocationHolderId + ' .fields.wait').hide()
        $(global.geolocationHolderId + ' .fields.normal').show()
        $(global.geolocationHolderId + '-button').show()
    },

    toolTipInit() {
        //tool-tip-hooks
    }

}
export default global
