import browser from 'imports/browser-detect'
import Cookies from 'imports/js-cookie'
import siteCommon from 'site/site-common'
let site = {
    browser:browser(),
    init: function () {
        //add browser name
        $('body').addClass("browser_" + site.browser.name);

        //mobile stuff
        $('.ui-link').removeClass('ui-link');

        //disable img drag
        $('img').on('dragstart', function (event) {
            event.preventDefault();
        });

        if (site.browser.mobile) {
            $('body').addClass("device-mobile");
        } else {
            $('body').addClass("device-notmobile");
        }

        site.languageDetect()

        window.addEventListener('resize', site.resize)
        site.resize()
    },
    languageDetect() {
        const params = new URLSearchParams(window.location.search);
        if (params.has('l')) {
            // this means browser was forced to be a different language
            site.setCookieAsync('lang', site_lang, { expires: 365 * 100 }).then(() => {
            });
        } else {
            let lang = Cookies.get('lang')
            if (!lang) {
                const preferredLanguage = navigator.language || navigator.userLanguage;
                lang = preferredLanguage.split('-')[0]
            }
            if (site_lang !== lang && site_languages.includes(site_lang)) {
                site.switchUrlToLanguage(lang)
            }
        }
    },
    setCookieAsync(name, value, options) {
        return new Promise(resolve => {
            Cookies.set(name, value, options);
            resolve();
        });
    },
    switchUrlToLanguage(lang) {
        const uri = (site_uri==='__home__') ? '' :  site_uri
        Cookies.set('lang', lang, { expires: 365 * 100 })
        site.setCookieAsync('lang', lang, { expires: 365 * 100 }).then(() => {
            if (lang === 'en') {
                window.location = "/" + uri
            } else {
                window.location = '/' + lang + '/' + uri
            }
        });

    },
    resize () {
        // First we get the viewport height then we multiply it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
        let ol = (window.innerWidth - 1920) /2
        if (ol <0) ol = 0
        document.documentElement.style.setProperty('--offsetLeft', `${ol}px`)
        site.siteOrientationChange()
    },
    siteOrientationChange () {
        //if (isMobile) {
        //     setTimeout(function () {
        //         //t.showBlocker = (t.window.innerWidth > t.window.innerHeight)
        //         //if( (window.orientation === 90 && $( window ).width() < 780) || (window.orientation === -90 && $( window ).width() < 780) ) // Landscape {
        //     }, 100)
        //}
    },
}
export default site
