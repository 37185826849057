import filters from 'site/filters'
let combobox = {
    currentMobileShowtimeFilter: null,
    init() {
        $('.combobox .combobox-display').on('click', combobox.toggleDropdown)
        //$('body.device-notmobile .combobox .selected').on('mouseenter', combobox.openDropdown)
        //$('body.device-notmobile .combobox').on('mouseleave', combobox.closeDropdown)
        $('.btn-combobox-hook').on('click', combobox.clickItem)


        let dataInitialComboboxType = $('.combobox').attr('data-initial-combobox-type')
        let dataInitialCombobox = $('.combobox').attr('data-initial-combobox')
        if (dataInitialComboboxType === 'showtimes') {
            combobox.currentMobileShowtimeFilter = dataInitialCombobox
            $(window).on( "resize", combobox.resize)
            combobox.resize()
        }
    },
    openDropdown() {
        const dropdownId = $(this).attr('data-combobox-id')
        $('#combobox-'+dropdownId).addClass('open')
    },
    closeDropdown() {
        $(this).removeClass('open')
    },
    toggleDropdown() {
        const dropdownId = $(this).attr('data-combobox-id')
        if ($('#combobox-'+dropdownId).hasClass('open')) {
            $('#combobox-'+dropdownId).removeClass('open')
        } else {
            $('#combobox-'+dropdownId).addClass('open')
        }
    },
    clickItem() {
        const dropdownId = $(this).attr('data-combobox-id')
        const filterOn = $(this).attr('data-filter-on')
        const filterConfig = $(this).attr('data-filter-config')

        const lbl = $(this).html()
        combobox.setFilter(dropdownId, filterOn, lbl, filterConfig)
    },
    setFilter(dropdownId, val, lbl, filterConfig='') {
        $('.filters .btn-combobox-hook').removeClass('active')
        $('.filters .btn-combobox-hook-'+val).addClass('active')

        $('#combobox-'+dropdownId + ' .combobox-display .lbl').html(lbl)
        $('#combobox-'+dropdownId).removeClass('open')

        if (filterConfig === 'showtimes') {
            $('.showtimes-table td.table-hook-all.special').hide()
            $('.showtimes-table td.table-hook-all.special-'+val).css({display:'flex'})
            combobox.currentMobileShowtimeFilter = val
        } else {
            // this is for international
            filters.setFilter(val)
        }
    },
    resize() {
        let docWidth = $(window).width();
        if (docWidth > 900 ) {
            $('.showtimes-table td.table-hook-all.special').css({display:'flex'})
        } else {
            $('.showtimes-table td.table-hook-all.special').hide()
            $('.showtimes-table td.table-hook-all.special-' + combobox.currentMobileShowtimeFilter).css({display:'flex'})
        }
    }
}
export default combobox