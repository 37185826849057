// core version + navigation, pagination modules:
import Swiper from 'swiper'
import { Navigation, Controller, Thumbs, Autoplay } from 'swiper/modules'

let gallery = {
    swiperThumbs: null,
    swiperBig: null,
    loopCount: 0,
    totalCount: 0,
    totalCountRepeated: 0,
    initialSlide: 0,
    init: function () {
        $("#featured-gallery .gallery-thumbs").css({'opacity':0})
        $("#featured-gallery .gallery-big .scrim").css({'opacity':0})

        gallery.loopCount = Number($("#featured-gallery").attr('data-loop-count'));
        gallery.totalCount = Number($("#featured-gallery .gallery-thumbs-outer").attr('data-len'));
        gallery.totalCountRepeated = gallery.loopCount * gallery.totalCount
        //gallery.initialSlide = (gallery.totalCount) + Math.floor(Math.random() * gallery.totalCount)
        gallery.initialSlide = 10 + Math.floor(Math.random() * gallery.totalCount)

        gallery.swiperThumbs = new Swiper("#featured-gallery .gallery-thumbs", {
            modules: [Navigation, Controller, Thumbs],
            init: false,
            //loop: true,
            //initialSlide: gallery.initialSlide,
            //watchSlidesProgress: true,
            slidesPerView: 10,
            breakpoints: {
                // when window width is >= Xpx
                0: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                400: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                500: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                },
                900: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                },
                1200: {
                    slidesPerView: 7,
                    spaceBetween: 20,
                },
                1600: {
                    slidesPerView: 10,
                    spaceBetween: 20,
                },
            }
        })
        gallery.swiperThumbs.on('afterInit', gallery.init2)
        gallery.swiperThumbs.init()
    },
    init2: function () {
        gallery.swiperBig = new Swiper("#featured-gallery .gallery-big", {
            modules: [Navigation, Controller, Thumbs, Autoplay],
            init: false,
            //loop: true,
            //initialSlide: gallery.initialSlide,
            slideToClickedSlide: true,
            spaceBetween: 0,
            slidesPerView: 1,
            navigation: {
                nextEl: "#featured-gallery .swiper-button-next",
                prevEl: "#featured-gallery .swiper-button-prev",
            },
            autoplay: {
                delay: 5000,
                disableOnInteraction: true,
                pauseOnMouseEnter: true
            },
        })
        gallery.swiperBig.on('afterInit', gallery.init3)
        gallery.swiperBig.on('slideNextTransitionEnd', function (e) {
            //console.log( 'slideNextTransitionEnd', e.activeIndex, e, gallery.totalCountRepeated, gallery.totalCount, gallery.totalCountRepeated-gallery.totalCount);
            if (e.activeIndex>=gallery.totalCountRepeated-gallery.totalCount-10) {
                gallery.swiperBig.slideTo(gallery.initialSlide)
                gallery.swiperThumbs.slideTo(gallery.initialSlide)

            }
        });
        gallery.swiperBig.init()
    },
    init3: function () {
        gallery.swiperThumbs.controller.control = gallery.swiperBig
        gallery.swiperBig.controller.control = gallery.swiperThumbs


        gallery.swiperBig.slideTo(gallery.initialSlide)
        gallery.swiperThumbs.slideTo(gallery.initialSlide)
        gallery.swiperBig.autoplay.start()

        setTimeout(function() {
            $("#featured-gallery .gallery-thumbs").css({'opacity':1})
            $("#featured-gallery .gallery-big .scrim").css({'opacity':1})
            $("#featured-gallery .gallery-big").css({'opacity':1})
        },350)
    }
}
export default gallery
