// core version + navigation, pagination modules:
import Swiper from 'swiper'
import { Autoplay } from 'swiper/modules'

let home = {
    swiperBig:null,
    init: function () {
        home.swiperBig  = new Swiper("#home-swiper-hook", {
            modules: [Autoplay],
            init:false,
            loop: true,
            centeredSlides: true,
            spaceBetween: 0,
            slidesPerView: 1,
            autoplay: {
                delay: 4000,
            },
        })
        home.swiperBig.on('transitionEnd', function (e) {
            let index = e.realIndex
            $('.mobile-films .sidenav-links .index').removeClass('active')
            $('.mobile-films .sidenav-links .index-'+index).addClass('active')
        })
        home.swiperBig.on('afterInit', function (e) {
            $('.mobile-films .sidenav-links .index-0').addClass('active')
            $('.mobile-films .sidenav-links .index-0').attr('wtf',1)
        })
        home.swiperBig.init()


    }
}
export default home
