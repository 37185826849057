let dropdowns = {
    init() {
        $('.dropdown .selected').on('click', dropdowns.toggleDropdown)
        $('body.device-notmobile .dropdown .selected').on('mouseenter', dropdowns.openDropdown)
        $('body.device-notmobile .dropdown').on('mouseleave', dropdowns.closeDropdown)

        // kill any hovers in mobile-content
        $('.mobile-content .dropdown .selected').off('click', dropdowns.toggleDropdown)
        $('.mobile-content .dropdown').each(function () {
            $('.selected', this).on('click', dropdowns.mobileClick)
            $('.selected', this).off('mouseenter', dropdowns.openDropdown)
            $(this).off('mouseleave', dropdowns.closeDropdown)
        });

        //search toggle
        $('.dropdown-search .tabs .tab').on('click', dropdowns.searchTabClick)

        // if doing search, set location as default tab
        if (typeof searchLocation !== 'undefined' && searchLocation) {
            $('.dropdown-search .tabs .tab').removeClass('active')
            $('.dropdown-search .tabs .tab-location').addClass('active')
            $('.dropdown-search .fields .field-shows').removeClass('active')
            $('.dropdown-search .fields .field-location').addClass('active')
        }
    },
    openDropdown() {
        const dropdownId = $(this).attr('data-dropdown-id')
        $('#dropdown-'+dropdownId).addClass('open')
    },
    closeDropdown() {
        $(this).removeClass('open')
    },
    toggleDropdown() {
        const dropdownId = $(this).attr('data-dropdown-id')
        dropdowns.toggleDropdown2(dropdownId)
    },
    toggleDropdown2(dropdownId) {
        if ($('#dropdown-'+dropdownId).hasClass('open')) {
            $('.dropdown').removeClass('open') //kill all
            $('#dropdown-'+dropdownId).removeClass('open')
        } else {
            $('.dropdown').removeClass('open') //kill all
            $('#dropdown-'+dropdownId).addClass('open')
        }
    },
    mobileClick() {
        const dropdownId = $(this).attr('data-dropdown-id')
        const dataMobileExtra = $(this).attr('data-mobile-extra')

        // always show bars - aka reset
        $('#mobile-nav-overlay .bars .bar').show()
        $('#mobile-nav-overlay .bars .bar').removeClass('full')

        if ($('#dropdown-'+dropdownId).hasClass('open')) {
            // if closing, do nothing to language bar
        } else {
            // if opening, hide one
            //$('#mobile-nav-overlay .bars .bar').addClass('full')
            if (dataMobileExtra === 'search') {
                $('#mobile-nav-overlay .bars .bar-language').hide()
            }
            if (dataMobileExtra === 'language') {
                $('#mobile-nav-overlay .bars .bar-search').hide()
            }
        }

        //now handle dropdowns
        dropdowns.toggleDropdown2(dropdownId)
    },

    searchTabClick() {
        const tab = $(this).attr('data-tab')
        $('.dropdown-search .tabs .tab').removeClass('active')
        $('.dropdown-search .tabs .tab-'+tab).addClass('active')
        $('.dropdown-search .fields .field').removeClass('active')
        $('.dropdown-search .fields .field-'+tab).addClass('active')
    }
}
export default dropdowns