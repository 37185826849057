import gsap, {Power4} from 'imports/gsap'
import dayjs from 'imports/dayjs'
let filters = {
    doMultiFilters: false,
    temporaryFilterClass: '',
    filter1Active: '',
    filter2Active: '',
    filter3ActiveArray: [],
    filterWeekActive: '',
    showInfoPopupInOpen: false,
    init() {
        $('.filters .btn-filter-hook').on('click', filters.click)
        $('.filters .btn-filter-hook2').on('click', filters.click2)

        $('.filters-popup-hook').on('click', filters.popupOpen)
        $('.enhanced-filters .overlay-x').on('click', filters.popupClose)
        $('.filter-apply-hook').on('click', filters.popupClose)


        $('.special-show-filter-hook').on('click', filters.showInfoPopupOpen)
        $('#modalPopupOverlay .overlay-x').on('click', filters.showInfoPopupClose)


        $('.btn-filter-hook3').on('click', filters.click3)
        $(document).keyup(function (e) {
            if (e.keyCode === 27) { // escape key
                if (filters.showInfoPopupInOpen) {
                    filters.showInfoPopupClose();

                }
                filters.popupClose();
            }
        })

        //closeables
        $(`.filter-hook-closeable`).on('click', filters.closeableChipClick)


        // initial
        const filterOn = $('.filters').attr('data-initial-filter')
        const filterOn2 = $('.filters').attr('data-initial-filter2')
        const filterOn3 = $('.filters').attr('data-initial-filter3')
        const filterWeek = $('.filters').attr('data-initial-filter-week')
        filters.filter1Active = filterOn
        filters.filter2Active = filterOn2
        filters.filter3ActiveArray = []
        filters.filterWeekActive = filterWeek
        filters.setFilter(filterOn)
        if (filterOn2) {
            filters.doMultiFilters = true
            filters.setFilter2(filterOn2)
        }
        if (filterOn3) {
            filters.filter3ActiveArray = filterOn3.split(',')
            filters.filter3ActiveArray.forEach(function (filterClass) {
                filters.setButton3(filterClass, true)
            })
            filters.setFilter3()
        }

        // clear date button
        $('.filter-hook-date-clear').on('click', filters.filterClearDate)
        if (filters.filterWeekActive) {
            $('.filter-hook-date-clear').show()
        }

        filters.specialSpecialEventCheck()

        filters.initWeekPicker()
    },

    initWeekPicker() {
        //https://jsfiddle.net/manishma/AVZJh/light/
        let params = {
            dateFormat: 'yy-mm-dd', //even though it is 4 digit use two digit year
            firstDay: 1,
            minDate: 0, // Disable past dates
            maxDate: '+1y',
            showOtherMonths: true,
            selectOtherMonths: true,
            dayNamesMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat" ], //start on sunday always even if firstDay is not 0
            onSelect: function(dateText, inst) {
                let date = $(this).datepicker('getDate');

                // start - Monday
                //let startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - (date.getDay() === 0 ? 6 : date.getDay() - 1));
                //let startOfWeek = startDate.toISOString().slice(0, 10);

                // end - sunday
                let endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + (7 - date.getDay()) % 7);
                let endOfWeek = endDate.toISOString().slice(0, 10);

                // Construct URL parameters
                let params = '';
                if (endOfWeek) {
                    params += '?week=' + endOfWeek;
                }
                params = filters.setNewPushState(params)
                filters.filterDateLoad(site_currentUrlBase + params)

            },
        }
        // if date filtering
        if (filters.filterWeekActive) {
            params.defaultDate = filters.filterWeekActive
        }

        $('.week-picker').datepicker( params);

        // current week
        window.setTimeout(function () {
            let activeElement = $('.week-picker').find('.ui-state-active');
            if (activeElement.length > 0) {
                activeElement.closest('tr').addClass('active-row');
            }
        }, 300);
    },

    filterClearDate() {
        let params = ''
        if (filters.filter1Active && filters.filter1Active !== 'all') {
            params = '?category=' + filters.filter1Active
        }
        filters.filterDateLoad(site_currentUrlBase + params)
    },

    // selectCurrentWeek() {
    //     window.setTimeout(function () {
    //         //$('.week-picker').find('.ui-datepicker-current-day a').addClass('ui-state-active')
    //         $('.week-picker').find('.ui-datepicker-current-day a').closest('tr').addClass('active-row');
    //     }, 1);ui-state-active
    // },


    popupOpen() {
        $('.enhanced-filters').show();
    },
    popupClose() {
        $('.enhanced-filters').hide();
    },
    showInfoPopupOpen() {
        let meta = $('.meta', this).html()
        $('#modalPopupOverlay .inner2').html(meta);
        gsap.fromTo($('#modalPopupOverlay'), 1,
            {
                opacity: 0,
            },
            {
                opacity: 1,
                delay: 0,
                ease: Power4.easeInOut,
                onComplete: filters.showInfoPopupOpen2,
            }
        )
        $('#modalPopupOverlay').css('visibility', 'visible')
    },
    showInfoPopupOpen2() {
        filters.showInfoPopupInOpen = true
    },
    showInfoPopupClose() {
        gsap.to($('#modalPopupOverlay'), 1,
            {
                opacity: 0,
                delay: 0,
                ease: Power4.easeInOut,
                onComplete: filters.showInfoPopupClose2,
            }
        )
    },
    showInfoPopupClose2() {
        $('#modalPopupOverlay .inner2').html('');
        $('#modalPopupOverlay').css('visibility', 'hidden')
        filters.showInfoPopupInOpen = false
    },
    specialSpecialEventCheck() {
        let items = $('.grid-holder .grid .filter-data-hook-special-event')
        if (items.length > 0) {
            $('.filter-hook-special-event-check-special-event').show()
        } else {
            $('.filter-hook-special-event-check-special-event').hide()
        }
    },

    click() {
        const filterOn = $(this).attr('data-filter-on')
        if (filters.doMultiFilters) {
            filters.filter1Active = filterOn
            filters.multiFilterClick()
        } else {
            filters.setFilter(filterOn)
        }

        filters.setNewPushState('')
    },


    setFilter(val) {
        filters.filter1Active = val
        filters.setButton(val)
        if (val === 'all') {
            $('.filter-data-hook').show()
        } else {
            $('.filter-data-hook').hide()
            $('.filter-data-hook-'+val).show()
        }

        $('.sec-hook').hide()
        if (val === 'london-west-end') {
            $('#sec-hook-london').show()
        } else {
            $('#sec-hook-regular').show()
        }

        //set closeable chip
        filters.setCloseableChip('category', val)


        filters.showHideNoMatchesText()
    },
    setButton(val) {
        $('.filters .btn-filter-hook').removeClass('active')
        $('.filters .btn-filter-hook-'+val).addClass('active')
    },

    click2() {
        const filterOn2 = $(this).attr('data-filter-on2')
        if (filters.doMultiFilters) {
            filters.filter2Active = filterOn2
            filters.multiFilterClick()
        } else {
            filters.setFilter2(filterOn2)
        }

        filters.setNewPushState('')
    },
    setFilter2(val) {
        filters.filter2Active = val
        filters.setButton2(val)
        if (val === 'all') {
            $('.filter-data-hook2').show()
        } else {
            $('.filter-data-hook2').hide()
            $('.filter-data-hook2-'+val).show()
        }

        //set closeable chip
        filters.setCloseableChip('playing', val)

        filters.showHideNoMatchesText()
    },

    setButton2(val) {
        $('.filters .btn-filter-hook2').removeClass('active')
        $('.filters .btn-filter-hook2-'+val).addClass('active')
    },
    multiFilterClick() {
        let filterOn = filters.filter1Active
        let filterOn2 = filters.filter2Active
        filters.setButton(filterOn)
        filters.setButton2(filterOn2)

        // if one is all, then just do the other
        if (filterOn === 'all') {
            filters.setFilter2(filterOn2)
        }
        if (filterOn2 === 'all') {
            filters.setFilter(filterOn)
        }
        if (filterOn !== 'all' && filterOn2 !== 'all') {
            //hide all
            $('.filter-data-hook').hide()

            filters.setCloseableChip('category', filterOn)
            filters.setCloseableChip('playing', filterOn2)

            // array of items to show from filter1
            let filter1ToShow = []
            let class1ToCheck = 'filter-data-hook-'+filterOn
            let class2ToCheck = 'filter-data-hook2-'+filterOn2
            $('.filter-data-hook').each(function () {
              if ($(this).hasClass(class1ToCheck) && $(this).hasClass(class2ToCheck)) {
                  $(this).show()
              }
            })
        }

        // now apply filter three at end
        if (filters.filter3ActiveArray.length) {
            filters.setFilter3()
        }


        filters.showHideNoMatchesText()

    },


    click3() {
        const filterOn3 = $(this).attr('data-filter-on3')
        const addActive = !($(this).hasClass('active'))
        filters.click3Final(filterOn3, addActive)

        filters.setNewPushState('')
    },
    click3Final(filterOn3, addActive) {
        $(`.filter-hook-closeable-specialinfo`).hide() // hide all closesblaes and re-add in multiFilterClick

        if (addActive) {
            filters.filter3ActiveArray.push(filterOn3)
        } else {
            filters.filter3ActiveArray = filters.filter3ActiveArray.filter(function(item) {
                return item !== filterOn3;
            });
        }
        filters.setButton3(filterOn3, addActive)

        filters.multiFilterClick()
    },
    setFilter3() {
        // this comes after filter 1 and 2, which alreayd sets the items
        // dont do antyhign if empty.  if not empty, just hide anythign not checked

        if (filters.filter3ActiveArray.length > 0) {
            // Loop through all elements with class 'filter-data-hook'
            $('.filter-data-hook').each(function () {
                var element = $(this);
                var matchesFilter = false;

                // Check if the element has any of the active filter classes
                filters.filter3ActiveArray.forEach(function (filterClass) {
                    filters.setCloseableChip('specialinfo', filterClass)
                    if (element.hasClass('filter-data-hook3-' + filterClass)) {
                        matchesFilter = true;
                    }
                });

                // Hide elements that don't match any active filter
                if (!matchesFilter) {
                    element.hide();
                }
            });
        }


        filters.showHideNoMatchesText()
    },
    setButton3(val, addActive) {
        if (addActive) {
            $('.filters .btn-filter-hook3-'+val).addClass('active') // filter button
            $('.special-show-filter-hook-'+val).show() // info icon in each show
        } else {
            $('.filters .btn-filter-hook3-'+val).removeClass('active')  // filter button
            $('.special-show-filter-hook-'+val).hide() // info icon in each show
        }
    },


    setCloseableChip(chipType, slug) {
        if (chipType === 'category') {
            //always hide closeables, then show. never show all
            $(`.filter-hook-closeable-${chipType}`).hide()
            if (slug !== 'all') {
                $(`.filter-hook-closeable-${chipType}-${slug}`).show()
            }
        } else if (chipType === 'playing') {
            //always hide closeables, then show. never show all
            $(`.filter-hook-closeable-${chipType}`).hide()
            if (slug !== 'all') {
                $(`.filter-hook-closeable-${chipType}-${slug}`).show()
            }
        } else if (chipType === 'specialinfo') {
            //never hide other closeables.  just keep showing more. never show all
            if (slug !== 'all') {
                $(`.filter-hook-closeable-${chipType}-${slug}`).show()
            }
        }
    },
    closeableChipClick() {
        const chipType = $(this).attr('data-chiptype')
        if (chipType === 'category') {
            filters.setFilter('all') //close by simulating "all" click
        } else if (chipType === 'playing') {
            filters.setFilter2('all') //close by simulating "all" click
        } else if (chipType === 'specialinfo') {
            const val = $(this).attr('data-value')
            filters.click3Final(val, false)
        }

        filters.setNewPushState()
    },


    showHideNoMatchesText() {
        $('.no-matches').hide()

        let items = $('.grid-holder .grid .item')
        let totalCount = items.length
        var displayNoneCount = items.filter(function() {
            return $(this).css('display') === 'none';
        }).length;
        if (displayNoneCount >= totalCount) {
            $('.no-matches').show()
        }

    },


    filterDateLoad(url) {
        let msg = '<div class="rte light"><h2>Loading</h2><p>Please wait...</p></div>'
        $('.enhanced-filters2').html(msg)
        $('.grid-holder .grid').hide()
        $('.no-matches .capper-padded').html(msg)
        $('.no-matches').show()
        $('.closeable-chips').hide()
        filters.popupClose();
        setTimeout(() => {
            window.location = url;
        }, 10);
    },

    // initParams is week, and only set when you use the date picker
    setNewPushState(initParams='') {
        let params = initParams
        if (!initParams) {
            if (filters.filterWeekActive) {
                params = '?week=' + filters.filterWeekActive
            }
        }
        if (filters.filter1Active && filters.filter1Active !== 'all') {
            params += (params === '' ? '?' : '&') + 'category=' + filters.filter1Active;
        }
        if (filters.filter2Active && filters.filter2Active !== 'all') {
            params += (params === '' ? '?' : '&') + 'playing=' + filters.filter2Active;
        }
        if (filters.filter3ActiveArray.length) {
            let si = ''
            filters.filter3ActiveArray.forEach(function (filterClass) {
                if (si !== '') {
                    si += ','
                }
                si += filterClass
            });
            params += (params === '' ? '?' : '&') + 'special=' + si
        }
        window.history.pushState(null, '', site_currentUrlBase +params );
        return params
    }
}
export default filters