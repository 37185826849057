let expandables = {
    init() {
        $('.expandables .row .top').on('click', expandables.toggle)
        $(window).on( "resize", expandables.resize);
        expandables.resize()
    },
    toggle() {
        const t = $(this).parent()
        if (t.hasClass('open')) {
            expandables.close(t)
        } else {
            expandables.open(t)
        }
    },
    open(t) {
        expandables.resize()
        t.addClass('open')
        const h = parseFloat(t.attr('theh'))
        $('.bottom', t).height(h)
    },
    close(t) {
        t.removeClass('open')
        $('.bottom', t).height(0)
    },
    resize(t) {
        $('.expandables .row').each(function () {
            let h = Math.ceil(  $('.bottom2', this).height() )
            h += 15
            const t = $(this)
            t.attr('theh', h)
            if (t.hasClass('open')) {
                $('.bottom', t).height(h)
            }
        })
    }
}
export default expandables