import QuickOneSwipe from 'site/components/QuickOneSwipe'
let quickOneSwipePrep = {
    init: function () {
        quickOneSwipePrep.setupSlides()
    },//init
    setupSlides: function () {
        $('.quick-one-swipe').each(function () {
            let id = $(this).attr('id')
            let slideshow = new QuickOneSwipe('#'+id)
            slideshow.init()
        });
    },
}
export default quickOneSwipePrep